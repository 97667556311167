import { Component, createContext } from 'react';
import i18n from '../helpers/i18n';
import { redeemTickets } from '../wallet/envelope/envelope.service';

export interface StateContext {
  state?: {},
  setInfo?: (value: Function) => void,
  setShowInfo?: (value: Function) => void,
  setActiveTicketId?: (value: Function) => void,
  showBanner?: (cfg: Function) => void,
  addToAccount?: (activeToken: string) => void,
  alreadyRedeemed?: (value: boolean) => void,
  setTmOrdersUrl?: (url: string) => void,
  setLang?: (lang: string) => void,
}

export const RootContext = createContext<StateContext>({} as StateContext);

class RootProvider extends Component {
  state = {
    info: null,
    showInfo: null,
    activeTicketId: null,
    banner: null,
    lang: i18n.language,
    tmOrdersUrl: 'https://my.ticketmaster.com/orders'
  };

  async addToAccount(activeToken: string) {
    let errorBanner = await redeemTickets(activeToken);
    if (!!errorBanner) {
      this.setState({ banner: errorBanner });
    }
  };

  render() {
    const stateContext: StateContext = {
      state: this.state,
      setInfo: (value) => this.setState({ info: value || null }),
      setShowInfo: (value) => this.setState({ showInfo: value || null }),
      setActiveTicketId: (value) => this.setState({ activeTicketId: value || null }),
      showBanner: (cfg) => this.setState({ banner: cfg || null }),
      addToAccount: (activeToken: string) => this.addToAccount(activeToken),
      alreadyRedeemed: (value: boolean) => this.setState({ alreadyRedeemed: value || false }),
      setTmOrdersUrl: (url: string) => this.setState({ tmOrdersUrl: url || this.state.tmOrdersUrl }),
      setLang: (lang: string) => this.setState({ lang }),
    };
    return (
      <RootContext.Provider value={stateContext}>
        {this.props.children}
      </RootContext.Provider>
    );
  }
}

export default RootProvider;
